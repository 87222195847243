/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';

import styles from 'assets/jss/material-dashboard-pro-react/components/dateRangeStyle.js';
import { isValidDate } from 'utils/helper.js';

const useStyles = makeStyles(styles);

export default function DateRange(props) {
  const classes = useStyles();
  const { startDate, endDate, setStartDate, setEndDate } = props;

  function handleStartDate(startObj) {
    if (typeof startObj === 'string') {
      return;
    }
    console.log(startObj);
    setStartDate(startObj);
    if (startObj.isAfter(endDate) && isValidDate(startObj)) {
      setEndDate(startObj);
    }
  }
  async function handleEndDate(endObj) {
    if (typeof endObj === 'string') {
      return;
    }
    setEndDate(endObj);
    if (endObj.isBefore(startDate) && isValidDate(endObj)) {
      setStartDate(endObj);
    }
  }
  return (
    <div>
      <FormControl>
        <Datetime
          timeFormat={false}
          inputProps={{ placeholder: 'MM/DD/YYYY' }}
          isValidDate={isValidDate}
          value={startDate}
          onChange={handleStartDate}
        />
      </FormControl>
      {' - '}
      <FormControl>
        <Datetime
          timeFormat={false}
          inputProps={{ placeholder: 'MM/DD/YYYY' }}
          isValidDate={isValidDate}
          value={endDate}
          onChange={handleEndDate}
        />
      </FormControl>
    </div>
  );
}

DateRange.propTypes = {
  startDate: PropTypes.objectOf(PropTypes.any).isRequired,
  endDate: PropTypes.objectOf(PropTypes.any).isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
};
