import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { saveAs } from 'file-saver';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// core components
import DateRange from 'components/DateRange/DateRange.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import ReactTable from 'components/ReactTable/ReactTable.js';

import Parse from 'services/Parse.js';
import { isValidDate, purchaseTotal } from 'utils/helper.js';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';
import dashboardStyles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  cardLeftButton: {
    ...dashboardStyles.cardLeftButton,
    right: '22px',
  },
  buttonProgress: {
    top: '16px',
    right: '44px',
    color: '#ff0000',
    position: 'absolute',
    '& svg': {
      all: 'initial',
      color: 'blue',
    },
  },
  loadingProgress: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  },
};

const today = moment(new Date());
const useStyles = makeStyles(styles);

export default function PaymentPage({ ticketBusiness }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [exporting, setExporting] = useState(false);
  const [startDate, setStartDate] = useState(today.clone().startOf('month'));
  const [endDate, setEndDate] = useState(today.clone());
  // const [startDate, setStartDate] = useState(moment(new Date('08/01/2020')));
  // const [endDate, setEndDate] = useState(moment(new Date('08/01/2020')));

  async function exportPayments(event) {
    event.persist();
    event.preventDefault();
    setExporting(true);
    const formatStart = startDate.format('MM/DD/YYYY');
    const formatEnd = endDate.format('MM/DD/YYYY');
    const text = await Parse.Cloud.run('GetPurchaseReport', {
      ticketBusinessId: ticketBusiness.id,
      startDate: formatStart,
      endDate: formatEnd,
      timezone: ticketBusiness.get('timezone'),
    });
    const blob = new Blob([text], {
      type: 'text/csv;charset=utf8;',
    });
    saveAs(blob, `${ticketBusiness.get('name')}_${formatStart}_to_${formatEnd}_payments.csv`);
    setExporting(false);
  }

  useEffect(() => {
    function goToCustomer(purchase) {
      window.open(
        `https://dashboard.stripe.com/${ticketBusiness.get('stripeAccount')}/payments/${purchase.chargeId}`,
        '_blank',
      );
    }

    const fetchData = async () => {
      if (!(isValidDate(startDate) && isValidDate(endDate) && startDate.isSameOrBefore(endDate))) {
        return;
      }
      const purchases = await Parse.Cloud.run('GetPurchaseWithDateRange', {
        ticketBusinessId: ticketBusiness.id,
        startDate: startDate.format('MM/DD/YYYY'),
        endDate: endDate.format('MM/DD/YYYY'),
        timezone: ticketBusiness.get('timezone'),
      });
      const tableData = purchases.map((purchase) => {
        let action = (
          <Button
            round
            simple
            color="info"
          >
            Cash
          </Button>
        );
        if (!purchase.chargeId || purchase.chargeId === '') {
          if (purchase.isCard) {
            action = (
              <Button
                round
                simple
                color="info"
              >
                Card
              </Button>
            );
          }
        } else if (purchase.chargeId.startsWith('ch_')) {
          action = (
            <Button
              round
              simple
              color="info"
              onClick={() => goToCustomer(purchase, ticketBusiness)}
            >
              Stripe
            </Button>
          );
        }
        return {
          id: purchase._id,
          orderNumber: purchase.orderNumber,
          amount: purchaseTotal(purchase),
          status: purchase.status,
          created: moment.tz(
            new Date(purchase._created_at),
            ticketBusiness.get('timezone'),
          ).format('MM/DD/YYYY h:mm a'),
          actions: (
            <div>
              {action}
            </div>
          ),
        };
      });
      setData(tableData);
      setLoading(false);
    };
    fetchData();
  }, [startDate, endDate, ticketBusiness]);

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <DateRange
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
            {data.length > 0 && (
              <div>
                <Button
                  className={classes.cardLeftButton}
                  color="info"
                  simple={!exporting}
                  disabled={exporting}
                  onClick={exportPayments}
                >
                  Export
                </Button>
                {exporting && (
                  <div className={classes.buttonProgress}>
                    <CircularProgress size={24} />
                  </div>
                )}
              </div>
            )}
          </CardHeader>
          <CardBody>
            {loading && (
              <div className={classes.loadingProgress}>
                <CircularProgress />
              </div>
            )}
            {!loading && data.length === 0 && (
              <div style={{ width: '100%', fontSize: '16px', textAlign: 'center' }}>No Data Available</div>
            )}
            {!loading && data.length > 0 && (
              <ReactTable
                columns={[
                  {
                    Header: 'Order #',
                    accessor: 'orderNumber',
                  },
                  {
                    Header: 'Amount',
                    accessor: 'amount',
                  },
                  {
                    Header: 'Status',
                    accessor: 'status',
                  },
                  {
                    Header: 'Created',
                    accessor: 'created',
                  },
                  {
                    Header: '',
                    accessor: 'actions',
                  },
                ]}
                data={data}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

PaymentPage.propTypes = {
  ticketBusiness: PropTypes.any.isRequired,
};
