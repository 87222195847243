import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// core components
import PendingView from 'components/PendingView/PendingView.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Parse from 'services/Parse.js';

import OrderView from './Order.js';

function PendingPage({ ticketBusiness }) {
  const [pendingOrders, setPendingOrders] = useState([]);
  const [pendingSubscription, setPendingSubscription] = useState(null);

  useEffect(() => {
    const getPendingOrders = async () => {
      console.log(ticketBusiness.id);
      const orders = await Parse.Cloud.run('GetPendingOrders', { ticketBusinessId: ticketBusiness.id });
      console.log(orders);
      setPendingOrders(orders);
    };
    getPendingOrders();
    const query = new Parse.Query('Purchase');
    query.equalTo('ticketBusiness', ticketBusiness);
    query.equalTo('status', 'pending');
    query.subscribe().then((subscription) => {
      setPendingSubscription(subscription);
      subscription.on('create', () => {
        getPendingOrders().then(() => {}).catch(() => {});
      });
      subscription.on('update', () => {
        getPendingOrders().then(() => {}).catch(() => {});
      });
    });
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (pendingSubscription) {
        pendingSubscription.unsubscribe();
      }
    };
  }, []);
  if (pendingOrders.length === 0) {
    return (
      <div>
        You have no pending orders!
      </div>
    );
  }
  return (
    <div>
      {pendingOrders.map((order) => (
        <GridContainer justify="center" key={order._id}>
          <GridItem xs={12} sm={8}>
            <PendingView
              validate
              steps={[
                { stepName: 'About', stepComponent: OrderView, stepId: 'about' },
              ]}
              title={order.firstName}
              subtitle={`#${order.orderNumber}`}
              order={order}
              finishButtonClick={(e) => alert(e)}
            />
          </GridItem>
        </GridContainer>
      ))}
    </div>
  );
}

PendingPage.propTypes = {
  ticketBusiness: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PendingPage;
