/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CardBody from 'components/Card/CardBody.js';
import Card from 'components/Card/Card.js';

import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";
import { processSalesLogs, formatSelectedKey , formatter } from 'utils/helper.js';

const useStyles = makeStyles(styles);

export default function SalesChart(props) {
  const classes = useStyles();
  const { interval, startDate, endDate, logData, metrics, selectedKey, title, label } = props;
  function handleClick() {
    console.log('clicked me');
  }
  let description = null;
  if (label) {
    description = (
      <div style={{ color: '#ccc', fontSize: '12px' }}>
        {label}
      </div>
    );
  }
  const data = processSalesLogs(metrics, selectedKey, interval, startDate, endDate);
  return (
    <GridItem xs={12} sm={12} md={6}>
      <Card chart>
        <CardBody>
          <h5 className={classes.cardTitle} style={{ fontWeight: 400 }}>
            {title}
          </h5>
          {description}
          <div style={{ color: '#3880ff', fontSize: '16px' }}>
            {logData ? formatSelectedKey(logData, selectedKey) : '$0.00'}
          </div>
          <ResponsiveContainer width="100%" height={150}>
            <BarChart
              data={data}
              margin={{ top: 20 }}
              onClick={handleClick}>
              <CartesianGrid horizontal={false} />
              <XAxis dataKey="name" interval="preserveEnd" />
              <Tooltip formatter={(value) => (selectedKey === 'count' || selectedKey === 'checkins' || selectedKey === 'shopViews' ? value : formatter.format(value))} />
              <Bar dataKey={selectedKey} fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </CardBody>
      </Card>
    </GridItem>
  );
}

SalesChart.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};
