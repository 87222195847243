import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

// material-ui icons
import Assignment from '@material-ui/icons/Assignment';
import Close from '@material-ui/icons/Close';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Table from 'components/Table/Table.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Parse from 'services/Parse.js';
import dashboardStyles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';
import modalStyles from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js';

const customerStyles = {
  ...dashboardStyles,
  ...modalStyles,
  buttonProgress: {
    top: '16px',
    right: '44px',
    color: '#ff0000',
    position: 'absolute',
    '& svg': {
      all: 'initial',
      color: 'blue',
    },
  },
};

const useStyles = makeStyles(customerStyles);

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

// TODO: format phone
export default function CustomerPage({ ticketBusiness }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [exporting, setExporting] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [metricData, setMetricData] = useState(null);
  const [classicModal, setClassicModal] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const { customers, metrics } = await Parse.Cloud.run('GetCustomers', { ticketBusinessId: ticketBusiness.id });
      const data = customers.map((info, i) => {
        const rank = (i + 1);
        return [rank, info.firstName, info.phone, formatter.format(info.totalSpent), info.count];
      });
      setMetricData(metrics);
      setTableData(data);
      setLoading(false);
    };
    fetchData();
  }, [ticketBusiness]);

  async function exportCustomers(event) {
    event.persist();
    event.preventDefault();
    setExporting(true);
    const text = await Parse.Cloud.run('GetCustomerReport', { ticketBusinessId: ticketBusiness.id });
    const blob = new Blob([text], {
      type: 'text/csv;charset=utf8;',
    });
    saveAs(blob, `${ticketBusiness.get('name')}_customers.csv`);
    setExporting(false);
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <p className={classes.cardCategory}>Total Customers</p>
              <h3 className={classes.cardTitle}>{metricData ? metricData.totalCustomers : 0}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                {/* <Update /> */}
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <p className={classes.cardCategory}>Repeat Customers</p>
              <h3 className={classes.cardTitle}>{metricData ? metricData.repeatCustomers : 0}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                {/* <Update /> */}
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <p className={classes.cardCategory}>Avg Gross Sale</p>
              <h3 className={classes.cardTitle}>{metricData ? formatter.format(metricData.avgTransactionSize) : '$0.00'}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                {/* <Update /> */}
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <p className={classes.cardCategory}>Repeat Ratio</p>
              <h3 className={classes.cardTitle}>{metricData ? `${metricData.repeatRatio}%` : '0%'}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                {/* <Update /> */}
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Top Customers
                {/* <small> (Last 5000 Transactions)</small> */}
              </h4>
              {tableData.length > 0 && (
                <div>
                  <Button
                    className={classes.cardLeftButton}
                    color="info"
                    simple={!exporting}
                    disabled={exporting}
                    onClick={exportCustomers}
                  >
                    Export
                  </Button>
                  {exporting && (
                    <div className={classes.buttonProgress}>
                      <CircularProgress size={24} />
                    </div>
                  )}
                </div>
              )}
            </CardHeader>
            <CardBody>
              {loading && (
                <div style={{
                  display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px',
                }}
                >
                  <CircularProgress />
                </div>
              )}
              {!loading && tableData.length === 0 && (
                <div style={{ width: '100%', fontSize: '16px', textAlign: 'center' }}>No Data Available</div>
              )}
              {!loading && tableData.length > 0 && (
                <Table
                  tableHeaderColor="primary"
                  tableHead={['#', 'Name', 'Phone', 'Spent', 'Visits']}
                  tableData={tableData}
                  coloredColls={[3]}
                  colorsColls={['info']}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Dialog
        classes={{
          root: `${classes.center} ${classes.modalRoot}`,
          paper: classes.modal,
        }}
        open={classicModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setClassicModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setClassicModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Modal title</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <p>
            Far far away, behind the word mountains, far from the
            countries Vokalia and Consonantia, there live the
            blind texts. Separated they live in Bookmarksgrove
            right at the coast of the Semantics, a large language
            ocean. A small river named Duden flows by their place
            and supplies it with the necessary regelialia. It is a
            paradisematic country, in which roasted parts of
            sentences fly into your mouth. Even the all-powerful
            Pointing has no control about the blind texts it is an
            almost unorthographic life One day however a small
            line of blind text by the name of Lorem Ipsum decided
            to leave for the far World of Grammar.
          </p>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button color="transparent">Nice Button</Button>
          <Button
            onClick={() => setClassicModal(false)}
            color="danger"
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CustomerPage.propTypes = {
  ticketBusiness: PropTypes.any.isRequired,
};
