import React, { useEffect, useState } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment';
import Dvr from '@material-ui/icons/Dvr';
import Favorite from '@material-ui/icons/Favorite';
import Close from '@material-ui/icons/Close';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardHeader from 'components/Card/CardHeader.js';
import ReactTable from 'components/ReactTable/ReactTable.js';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';

import Parse from 'services/Parse.js';

// import JsZip from 'jszip';
// import FileSaver from 'file-saver';

// const exportZip = blobs => {
//   const zip = JsZip();
//   blobs.forEach((blob, i) => {
//     zip.file(`file-${i}.csv`, blob);
//   });
//   zip.generateAsync({type: 'blob'}).then(zipFile => {
//     const currentDate = new Date().getTime();
//     const fileName = `combined-${currentDate}.zip`;
//     return FileSaver.saveAs(zipFile, fileName);
//   });
// }
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const useStyles = makeStyles(styles);

export default function AccountPage() {
  const [ticketBusinesses, setTicketBusinesses] = useState([]);
  async function goToAccount(account) {
    const user = Parse.User.current();
    user.set('ticketBusiness', account);
    await user.save();
    window.location.href = '/admin/payment';
  }
  useEffect(() => {
    const fetchData = async () => {
      const user = await Parse.User.currentAsync();
      await user.fetch();
      console.log(user.get('managedAccounts'));
      const query = new Parse.Query('TicketBusiness');
      query.containedIn('objectId', user.get('managedAccounts'));
      query.ascending('name');
      const accounts = await query.find();
      setTicketBusinesses(accounts);
    };
    fetchData();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <ReactTable
              columns={[
                {
                  Header: 'Name',
                  accessor: 'name',
                },
                // {
                //   Header: 'Amount',
                //   accessor: 'amount',
                // },
                {
                  Header: '',
                  accessor: 'actions',
                },
              ]}
              data={ticketBusinesses.map((account) => ({
                id: account.id,
                name: account.get('name'),
                // amount: formatter.format(account.get('amount')),
                actions: (
                  <div className="actions-right">
                    <Button
                      round
                      simple
                      color="info"
                      onClick={() => goToAccount(account)}
                    >
                      View
                    </Button>
                    {' '}
                  </div>
                ),
              }))}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
