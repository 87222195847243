import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardFooter from 'components/Card/CardFooter.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.js';
import Parse from 'services/Parse.js';

const useStyles = makeStyles(styles);

export default function LoginPage() {
  // TODO: Country Code
  // TODO: Phone Validation
  const classes = useStyles();
  const [cardAnimaton, setCardAnimation] = useState('cardHidden');
  const [sent, setSent] = useState(false);
  const [code, setCode] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasLoggedIn, setHasLoggedIn] = useState(false);

  async function handleCode(value) {
    setCode(value);
    if (value.length === 4) {
      try {
        setLoading(true);
        const installationId = await Parse.CoreManager.getInstallationController().currentInstallationId();
        const sessionToken = await Parse.Cloud.run('logIn', {
          phoneNumber: phone,
          codeEntry: value,
          isPOS: true,
          installationId,
        });
        const user = await Parse.User.become(sessionToken);
        await user.fetchWithInclude('ticketBusiness');
        setHasLoggedIn(true);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setError(e);
        setLoading(false);
      }
    }
  }

  function sendCode() {
    setSent(true);
    Parse.Cloud.run('sendCode', { phoneNumber: phone, isPOS: true }).catch((e) => {
      setError(e.message);
      setSent(false);
    });
  }

  function handlePhone(num) {
    setPhone(num);
  }
  useEffect(() => {
    const id = setTimeout(() => {
      setCardAnimation('');
    }, 700);
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  if (hasLoggedIn) {
    return <Redirect to="/admin/dashboard" />;
  }
  let body = (
    <div>
      <CardBody>
        <CustomInput
          labelText="Phone Number..."
          id="firstname"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: phone,
            onChange: (event) => handlePhone(event.target.value),
          }}
        />
      </CardBody>
      <CardFooter className={classes.justifyContentCenter}>
        <Button color="rose" simple size="lg" block onClick={sendCode}>
          Send
        </Button>
      </CardFooter>
    </div>
  );
  if (sent) {
    body = (
      <div>
        <CardBody>
          <CustomInput
            autoFocus
            labelText="Enter Code"
            id="firstname"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              textCenter: true,
              disabled: loading,
              maxLength: 4,
              placeholder: 'XXXX',
              value: code,
              onChange: (event) => handleCode(event.target.value),
            }}
          />
          {loading && (
            <div className={classes.inputProgress}>
              <CircularProgress size={24} />
            </div>
          )}
          {error && <div>Invalid Code</div>}
        </CardBody>
        <CardFooter className={classes.justifyContentCenter}>
          <Button color="rose" simple size="lg" block onClick={sendCode} disabled={loading}>
            Resend Code
          </Button>
        </CardFooter>
      </div>
    );
  }
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
              >
                <h4 className={classes.cardTitle}>Log In</h4>
              </CardHeader>
              {body}
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
