import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';

const orderStyle = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  inputAdornment: {
    position: 'relative',
  },
};
const useStyles = makeStyles(orderStyle);

function Order({ order }) {
  const classes = useStyles();
  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12}>
        <h4 className={classes.infoText}>
          Total
        </h4>
        <h4 className={classes.infoText}>
          $5.00
        </h4>
      </GridItem>
      <GridItem xs={6} sm={6} md={6} lg={6}>
        <Button style={{ width: '100%' }} color="warning">
          In Progress
        </Button>
      </GridItem>
      <GridItem xs={6} sm={6} md={6} lg={6}>
        <Button style={{ width: '100%' }} color="info">
          Complete
        </Button>
      </GridItem>
    </GridContainer>
  );
}

Order.propTypes = {
  order: PropTypes.object,
};

export default Order;
