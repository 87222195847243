import * as React from 'react';

import './ReaderIcon.css';

class ReaderIcon extends React.Component {
  render() {
    return <span className="ReaderIcon" />;
  }
}

export default ReaderIcon;
