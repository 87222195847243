import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

import { breakpoints } from '../../styles.jsx';

const commonCSS = {
  background: '#ffffff',
  boxShadow: '0 3px 6px 0 rgba(0,0,0,0.07)',
  flexShrink: 0,
  padding: '16px',
  [breakpoints.laptop]: {
    width: '310px',
  },
  [breakpoints.mobile]: {
    width: '100%',
  },
  borderBottom: '1px solid #e3e8ee',
  display: 'block',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export default function Section(props) {
  const { alignment, children, position } = props;
  let borderRadius;
  switch (position) {
    case 'first':
      borderRadius = '14px 14px 0 0';
      break;
    case 'last':
      borderRadius = '0 0 14px 14px';
      break;
    case 'middle':
      borderRadius = '0 0 0 0';
      break;
    default:
      borderRadius = '14px 14px 14px 14px';
  }

  return (
    <div
      className={css({
        ...commonCSS,
        borderRadius,
        ...alignment,
      })}
    >
      {children}
    </div>
  );
}

Section.defaultProps = {
  alignment: {},
  children: null,
  position: '',
};

Section.propTypes = {
  alignment: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.objectOf(PropTypes.any),
  position: PropTypes.string,
};
