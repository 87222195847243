import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';

import {
  BarChart, Bar, Cell, XAxis, CartesianGrid, PieChart, Pie, Legend, Tooltip, ResponsiveContainer,
} from 'recharts';
import moment from 'moment-timezone';

// @material-ui/core components
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// core components
import DateRange from 'components/DateRange/DateRange.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomLinearProgress from 'components/CustomLinearProgress/CustomLinearProgress.js';
import SalesChart from 'components/SalesChart/SalesChart.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';
import Parse from 'services/Parse.js';
import {
  COLORS, processIncentiveLogs, processSalesLogs, formatSelectedKey, formatter,
} from 'utils/helper.js';

const dashboardStyle = {
  ...styles,
  cardLeftButton: {
    ...styles.cardLeftButton,
    top: '16px',
    right: '22px',
  },
  buttonProgress: {
    top: '30px',
    right: '66px',
    color: '#ff0000',
    position: 'absolute',
    '& svg': {
      all: 'initial',
      color: 'blue',
    },
  },
};

const useStyles = makeStyles(dashboardStyle);

const selectMap = [
  { name: 'Shop Views', value: 'shopViews' },
  { name: 'Checkins', value: 'checkins' },
  { name: 'Gross', value: 'gross' },
  { name: 'Net Sales', value: 'net' },
  { name: 'Total Payments', value: 'count' },
  { name: 'Average Gross Sales', value: 'average' },
  { name: 'Total Tips', value: 'tip' },
  { name: 'Total Taxes', value: 'tax' },
  { name: 'Discounts', value: 'discount' },
  { name: 'Refunds', value: 'refund' },
  { name: 'Total Cash', value: 'cash' },
  { name: 'Subtotal Charge', value: 'subtotalFee' },
  { name: 'Shipping Fee', value: 'shippingFee' },
  { name: 'Service Fee', value: 'serviceFee' },
  { name: 'Total Rev-Share', value: 'revShare' },
  { name: 'Convenience Fee', label: '(Merchant Does Not Pay)', value: 'fee' },
  { name: 'CC Processing', value: 'stripeProcessing' },
  { name: 'Net Deposit', value: 'stripeNet' },
];

const incentiveMap = [
  { name: 'Net', value: 'net' },
  { name: 'Count', value: 'count' },
];

const today = moment(new Date());

export default function Dashboard({ ticketBusiness }) {
  const classes = useStyles();
  const [selectedKey, setSelectedKey] = React.useState('net');
  const [selectedIncentiveKey, setSelectedIncentiveKey] = React.useState('net');
  const [todayLogData, setTodayLogData] = useState(null);
  const [todayChartData, setTodayChartData] = useState(null);
  const [overviewLogData, setOverviewLogData] = useState(null);
  const [overviewChartData, setOverviewChartData] = useState(null);
  const [incentiveLogTotal, setIncentiveLogTotal] = useState(null);
  const [categoryMetrics, setCategoryMetrics] = useState(null);
  const [exporting, setExporting] = useState(false);
  const [incentiveData, setIncentiveData] = useState({ net: [], count: [] });
  const [categoryData, setCategoryData] = useState([]);
  const [todayData, setTodayData] = useState([]);
  const [interval, setInterval] = useState('day');
  const [startDate, setStartDate] = useState(today.clone().startOf('month'));
  const [endDate, setEndDate] = useState(today.clone().endOf('day'));
  const [selectedDrop, setSelectedDrop] = useState(-1);
  const [businessEvents, setBusinessEvents] = useState([]);

  async function exportIncentives(event) {
    event.persist();
    event.preventDefault();
    setExporting(true);
    const formatStart = startDate.format('MM/DD/YYYY');
    const formatEnd = endDate.format('MM/DD/YYYY');
    const text = await Parse.Cloud.run('GetIncentiveReport', {
      ticketBusinessId: ticketBusiness.id,
      startDate: formatStart,
      endDate: formatEnd,
      timezone: ticketBusiness.get('timezone'),
    });
    const blob = new Blob([text], {
      type: 'text/csv;charset=utf8;',
    });
    saveAs(blob, `${ticketBusiness.get('name')}_${formatStart}_to_${formatEnd}_product.csv`);
    setExporting(false);
  }

  useEffect(() => {
    const query = new Parse.Query('BusinessEvent');
    query.equalTo('ownedBy', ticketBusiness);
    query.equalTo('archived', false);
    query.select(['objectId', 'customName']);
    query.find({ json: true }).then((results) => {
      setBusinessEvents(results);
    });
  }, []);

  useEffect(() => {
    const fetchTodayData = async () => {
      const { logData, chartData } = await Parse.Cloud.run('GetSalesLogs', {
        interval: 'hour',
        ticketBusinessIds: [ticketBusiness.id],
        businessEventId: selectedDrop === -1 ? null : selectedDrop,
        startDate: today.format('MM/DD/YYYY'),
        timezone: ticketBusiness.get('timezone'),
      });
      setTodayChartData(chartData);
      setTodayLogData(logData);
    };
    fetchTodayData();
  }, [selectedDrop]);

  useEffect(() => {
    if (!todayChartData) {
      return;
    }
    console.log(processSalesLogs(todayChartData, selectedKey, 'hour', today));
    setTodayData(processSalesLogs(todayChartData, selectedKey, 'hour', today));
  }, [selectedKey, todayChartData]);

  useEffect(() => {
    if (!categoryMetrics) {
      return;
    }
    setCategoryData(processIncentiveLogs(categoryMetrics, selectedIncentiveKey));
  }, [selectedIncentiveKey, categoryMetrics]);

  useEffect(() => {
    const duration = Math.round(moment.duration(endDate.diff(startDate)).asDays());
    const inv = duration < 3 ? 'hour' : 'day';
    setInterval(inv);
    const fetchOverviewData = async () => {
      const { logData, chartData } = await Parse.Cloud.run('GetSalesLogs', {
        interval: inv,
        businessEventId: selectedDrop === -1 ? null : selectedDrop,
        ticketBusinessIds: [ticketBusiness.id],
        startDate: startDate.format('MM/DD/YYYY'),
        endDate: endDate.format('MM/DD/YYYY'),
        timezone: ticketBusiness.get('timezone'),
      });
      setOverviewChartData(chartData);
      setOverviewLogData(logData);
    };
    const fetchIncentiveLogs = async () => {
      const { incentiveTotal, incentiveLogMetrics, categoryLogMetrics } = await Parse.Cloud.run('GetIncentiveLogs', {
        ticketBusinessId: ticketBusiness.id,
        businessEventId: selectedDrop === -1 ? null : selectedDrop,
        startDate: startDate.format('MM/DD/YYYY'),
        endDate: endDate.format('MM/DD/YYYY'),
        timezone: ticketBusiness.get('timezone'),
      });
      setIncentiveData(incentiveLogMetrics);
      setIncentiveLogTotal(incentiveTotal);
      setCategoryMetrics(categoryLogMetrics);
    };
    fetchOverviewData();
    fetchIncentiveLogs();
  }, [startDate, endDate, ticketBusiness, selectedDrop]);

  return (
    <div>
      <h3>Today</h3>
      <Select
        disableUnderline
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        value={selectedDrop}
        onChange={(event) => setSelectedDrop(event.target.value)}
      >
        {[{ customName: 'ALL SALES', objectId: -1 }, ...businessEvents].map((item) => (
          <MenuItem
            key={item.customName}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.objectId}
          >
            {item.customName}
          </MenuItem>
        ))}
      </Select>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <FormControl
                className={classes.selectFormControl}
              >
                <Select
                  disableUnderline
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={selectedKey}
                  onChange={(event) => setSelectedKey(event.target.value)}
                  inputProps={{
                    name: 'simpleSelect',
                    id: 'simple-select',
                  }}
                >
                  {selectMap.map((item) => (
                    <MenuItem
                      key={item.name}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={item.value}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div style={{ color: '#3880ff', fontSize: '16px' }}>
                {todayLogData ? formatSelectedKey(todayLogData, selectedKey) : '$0.00'}
              </div>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={todayData}
                  margin={{
                    top: 20, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <Tooltip formatter={(value) => formatter.format(value)} />
                  <Bar dataKey={selectedKey} fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <h3>Report Overview</h3>
      <DateRange
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
      <GridContainer>
        {selectMap.map((item) => (
          <SalesChart
            key={`${item.name}_${item.value}`}
            title={item.name}
            label={item.label}
            interval={interval}
            startDate={startDate}
            endDate={endDate}
            logData={overviewLogData}
            metrics={overviewChartData}
            selectedKey={item.value}
          />
        ))}
      </GridContainer>
      <h4>Categories</h4>
      <DateRange
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <FormControl
                className={classes.selectFormControl}
              >
                <Select
                  disableUnderline
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={selectedIncentiveKey}
                  onChange={(event) => setSelectedIncentiveKey(event.target.value)}
                  inputProps={{
                    name: 'incentiveSelect',
                    id: 'incentive-select',
                  }}
                >
                  {incentiveMap.map((item) => (
                    <MenuItem
                      key={item.name}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={item.value}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div style={{ color: '#3880ff', fontSize: '16px' }}>
                {incentiveLogTotal ? formatSelectedKey(incentiveLogTotal, selectedIncentiveKey) : '$0.00'}
              </div>
              {true && (
                <div>
                  <Button
                    className={classes.cardLeftButton}
                    color="info"
                    simple={!exporting}
                    disabled={exporting}
                    onClick={exportIncentives}
                  >
                    Export
                  </Button>
                  {exporting && (
                    <div className={classes.buttonProgress}>
                      <CircularProgress size={24} />
                    </div>
                  )}
                </div>
              )}
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    isAnimationActive={false}
                    data={categoryData}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                    label
                  >
                    {
                      categoryData.map((entry, index) => <Cell key={entry.name} fill={COLORS[index]} />)
                    }
                  </Pie>
                  <Tooltip formatter={(value) => (selectedIncentiveKey === 'count' ? value : formatter.format(value))} />
                  <Legend
                    payload={
                      categoryData.map((item, index) => ({
                        id: item.name,
                        type: 'square',
                        value: `${item.name} (${(selectedIncentiveKey === 'count' ? item.value : formatter.format(item.value))})`,
                        color: COLORS[index],
                      }))
                    }
                  />
                </PieChart>
              </ResponsiveContainer>
              <h4>Top Categories</h4>
              {categoryData.map((data) => (
                <div key={data.name}>
                  <div>
                    <h5 style={{ fontSize: '16px' }}>{data.name}</h5>
                    <div style={{
                      display: 'inline-block',
                      float: 'right',
                      marginTop: '-30px',
                      fontSize: '16px',
                      color: '#3880ff',
                      fontWeight: '500',
                    }}
                    >
                      {(selectedIncentiveKey === 'count' ? data.value : formatter.format(data.value))}
                    </div>
                  </div>
                  <CustomLinearProgress
                    variant="determinate"
                    color="info"
                    value={(data.value / incentiveLogTotal[selectedIncentiveKey]) * 100}
                    style={{ width: 'calc(100% - 50px)', display: 'inline-block' }}
                  />
                  <div style={{
                    display: 'inline-block',
                    float: 'right',
                    marginTop: '-9px',
                    width: '50px',
                    textAlign: 'right',
                  }}
                  >
                    {`${((data.value / incentiveLogTotal[selectedIncentiveKey]) * 100).toFixed(1)}%`}
                  </div>
                </div>
              ))}
              <h4>Top Items</h4>
              {incentiveData[selectedIncentiveKey].map((data) => (
                <div key={data.id}>
                  <div style={{ height: '1px', border: '1px solid #eee', marginTop: '8px' }} />
                  <h5 style={{ fontWeight: '500' }}>{data.name}</h5>
                  <div style={{ display: 'inline-block' }}>Sales: </div>
                  <div style={{
                    display: 'inline-block', marginLeft: '4px', color: '#3880ff', fontWeight: '500',
                  }}
                  >
                    {(selectedIncentiveKey === 'count' ? data.amount : formatter.format(data.amount))}
                  </div>
                </div>
              ))}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

Dashboard.propTypes = {
  ticketBusiness: PropTypes.any.isRequired,
};
