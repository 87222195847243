import { grayColor } from 'assets/jss/material-dashboard-pro-react.js';

const gridSystemStyle = {
  title: {
    color: grayColor[2],
    textDecoration: 'none',
  },
};

export default gridSystemStyle;
