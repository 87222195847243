const Parse = require('parse');

const applicationId = 'MK5KVBqIzhhM5tIwX9hrKnQLLKpHeJ9O0VHS4Fqp';
const javascriptKey = 'CLStTSaozrHYjthLXzZkqoUCsJcgnFVQtb5rjtyu';
let serverURL = 'https://web-cashdrop.herokuapp.com/parse';
if (process.env.NODE_ENV === 'development') {
  serverURL = 'http://localhost:1337/parse';
}
Parse.initialize(applicationId, javascriptKey);
Parse.serverURL = serverURL;
Parse.CoreManager.set('REQUEST_ATTEMPT_LIMIT', 1);

const liveQueryURL = 'wss://sugr-live-query.herokuapp.com';
Parse.liveQueryServerURL = liveQueryURL;
Parse.enableEncryptedUser();
Parse.secret = applicationId;

export default Parse;
