import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

export default function MediaPlayer(props) {
  const { audioTrack, videoTrack } = props;
  const container = useRef(null);
  useEffect(() => {
    if (container.current && videoTrack) {
      videoTrack.play(container.current);
    }
    return () => {
      if (videoTrack) {
        videoTrack.stop();
      }
    };
  }, [container, videoTrack]);
  useEffect(() => {
    if (audioTrack) {
      audioTrack.play();
    }
    return () => {
      if (audioTrack) {
        audioTrack.stop();
      }
    };
  }, [audioTrack]);
  return (
    <div ref={container} className="video-player" style={{ width: '320px', height: '240px' }} />
  );
}

MediaPlayer.defaultProps = {
  audioTrack: undefined,
  videoTrack: undefined,
};
MediaPlayer.propTypes = {
  audioTrack: PropTypes.objectOf(PropTypes.any),
  videoTrack: PropTypes.objectOf(PropTypes.any),
};
