import React, { useEffect } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import PropTypes from 'prop-types';
import Parse from 'services/Parse.js';
import useAgora from './hooks/useAgora';
import MediaPlayer from './components/MediaPlayer';
import './Broadcast.css';

AgoraRTC.setLogLevel(4);
const client = AgoraRTC.createClient({ codec: 'h264', mode: 'rtc' });

export default function BroadcastPage({ ticketBusiness }) {
  const {
    localVideoTrack, leave, join, joinState, remoteUsers,
  } = useAgora(client);

  useEffect(() => {
    const channel = 'DvBP35YQIC';// ticketBusiness.id;
    Parse.Cloud.run('rtcToken', {
      channel,
      isPublisher: true,
    }).then(({ appId, token, uid }) => join(appId, channel, token, uid));
  }, []);

  return (
    <div className="call">
      <form className="call-form">
        <div className="button-group">
          <button
            id="leave"
            type="button"
            className="btn btn-primary btn-sm"
            disabled={!joinState}
            onClick={() => {
              leave();
            }}
          >
            Leave
          </button>
        </div>
      </form>
      <div className="player-container">
        <div className="local-player-wrapper">
          <p className="local-player-text">
            {localVideoTrack && 'localTrack'}
            {joinState && localVideoTrack ? `(${client.uid})` : ''}
          </p>
          <MediaPlayer videoTrack={localVideoTrack} audioTrack={undefined} />
        </div>
        {remoteUsers.map((user) => (
          <div className="remote-player-wrapper" key={user.uid}>
            <p className="remote-player-text">{`remoteVideo(${user.uid})`}</p>
            <MediaPlayer videoTrack={user.videoTrack} audioTrack={user.audioTrack} />
          </div>
        ))}
      </div>
    </div>
  );
}

BroadcastPage.propTypes = {
  ticketBusiness: PropTypes.any.isRequired,
};
