import React, { useCallback, useEffect, useState } from 'react';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import Client from './client';
import Logger from './logger';

import CartForm from './Forms/CartForm.js';
import ConnectionInfo from './ConnectionInfo/ConnectionInfo.js';
import Logs from './Logs/Logs.js';
import Readers from './Forms/Readers.js';
import ThermalPrinter from './Forms/TerminalPrinter.js';

let pendingPaymentIntentSecret = null;
const client = new Client('', 'acct_1F5Cy2HWL3MpCQgJ');

export default function TerminalPage() {
  const [terminal, setTerminal] = useState();
  const [state, setStates] = useState({
    status: 'requires_initializing', // requires_connecting || reader_registration || workflows
    discoveredReaders: [],
    connectionStatus: 'not_connected',
    reader: null,
    readerLabel: '',
    registrationCode: '',
    cancelablePayment: false,
    chargeAmount: 5100,
    currency: 'usd',
    workFlowInProgress: null,
    disoveryWasCancelled: false,
  });

  const setState = useCallback((data) => {
    setStates((prev) => ({ ...prev, ...data }));
  }, []);

  const isWorkflowDisabled = () => state.cancelablePayment || state.workFlowInProgress;

  const runWorkflow = async (workflowName, workflowFn) => {
    console.log(workflowName, workflowFn);
    setState({
      workFlowInProgress: workflowName,
    });
    try {
      await workflowFn();
    } finally {
      setState({
        workFlowInProgress: null,
      });
    }
  };

  const discoverReaders = useCallback(async () => {
    const discoverResult = await terminal.discoverReaders();

    if (discoverResult.error) {
      console.log('Failed to discover: ', discoverResult.error);
      return discoverResult.error;
    }
    setState({
      discoveredReaders: discoverResult.discoveredReaders,
    });
    return discoverResult.discoveredReaders;
  }, [setState, terminal]);

  const connectToReader = useCallback(async (selectedReader) => {
    const connectResult = await terminal.connectReader(selectedReader);
    if (connectResult.error) {
      console.log('Failed to connect:', connectResult.error);
      window.localStorage.removeItem('terminal.reader');
    } else {
      setState({
        status: 'workflows',
        discoveredReaders: [],
        reader: connectResult.reader,
      });
      window.localStorage.setItem('terminal.reader', JSON.stringify(selectedReader));
    }
  }, [setState, terminal]);

  const disconnectReader = async () => {
    await terminal.disconnectReader();
    setState({ reader: null });
  };

  const registerAndConnectNewReader = async (label, registrationCode, location) => {
    try {
      const reader = await client.registerDevice({
        label,
        registrationCode,
        location,
      });
      // After registering a new reader, we can connect immediately using the reader object returned from the server.
      await connectToReader(reader);
      console.log('Registered and Connected Successfully!');
    } catch (e) {
      // Suppress backend errors since they will be shown in logs
    }
  };

  // 3b. Collect a card present payment
  const collectCardPayment = async () => {
    // We want to reuse the same PaymentIntent object in the case of declined charges, so we
    // store the pending PaymentIntent's secret until the payment is complete.
    if (!pendingPaymentIntentSecret) {
      try {
        const paymentMethodTypes = ['card_present'];
        if (state.currency === 'cad') {
          paymentMethodTypes.push('interac_present');
        }
        const createIntentResponse = await client.createPaymentIntent({
          amount: state.chargeAmount,
          currency: state.currency,
          paymentMethodTypes,
        });
        pendingPaymentIntentSecret = createIntentResponse;
      } catch (e) {
        // Suppress backend errors since they will be shown in logs
        return;
      }
    }
    const paymentMethodPromise = terminal.collectPaymentMethod(
      pendingPaymentIntentSecret,
    );
    setState({ cancelablePayment: true });
    const result = await paymentMethodPromise;
    if (result.error) {
      console.log('Collect payment method failed:', result.error.message);
    } else {
      const confirmResult = await terminal.processPayment(
        result.paymentIntent,
      );
      // At this stage, the payment can no longer be canceled because we've sent the request to the network.
      setState({ cancelablePayment: false });
      if (confirmResult.error) {
        console.log(`Confirm failed: ${confirmResult.error.message}`);
      } else if (confirmResult.paymentIntent) {
        if (confirmResult.paymentIntent.status !== 'succeeded') {
          try {
            // Capture the PaymentIntent from your backend client and mark the payment as complete
            await client.capturePaymentIntent({
              paymentIntentId: confirmResult.paymentIntent.id,
            });
            pendingPaymentIntentSecret = null;
            console.log('Payment Successful!');
          } catch (e) {
            // Suppress backend errors since they will be shown in logs

          }
        } else {
          pendingPaymentIntentSecret = null;
          console.log('Single-message payment successful!');
        }
      }
    }
  };

  // 3c. Cancel a pending payment.
  // Note this can only be done before calling `processPayment`.
  const cancelPendingPayment = async () => {
    await terminal.cancelCollectPaymentMethod();
    pendingPaymentIntentSecret = null;
    setState({ cancelablePayment: false });
  };

  const updateChargeAmount = (amount) => setState({ chargeAmount: parseInt(amount, 10) });
  const updateCurrency = (currency) => setState({ currency });

  useEffect(() => {
    setTerminal(window.StripeTerminal.create({
      onFetchConnectionToken: async () => {
        const connectionTokenResult = await client.createConnectionToken();
        return connectionTokenResult;
      },
      onUnexpectedReaderDisconnect: Logger.tracedFn(
        'onUnexpectedReaderDisconnect',
        'https://stripe.com/docs/terminal/js-api-reference#stripeterminal-create',
        () => {
          console.log('Unexpected disconnect from the reader');
          setStates((prev) => ({
            ...prev,
            connectionStatus: 'not_connected',
            reader: null,
          }));
        },
      ),
      // 1c. (Optional) Create a callback that will be called when the reader's connection status changes.
      // You can use this callback to update your UI with the reader's connection status.
      onConnectionStatusChange: Logger.tracedFn(
        'onConnectionStatusChange',
        'https://stripe.com/docs/terminal/js-api-reference#stripeterminal-create',
        (ev) => {
          setStates((prev) => ({ ...prev, connectionStatus: ev.status, reader: null }));
        },
      ),
    }));
  }, []);

  useEffect(() => {
    if (!terminal) {
      return;
    }
    async function init() {
      const item = window.localStorage.getItem('terminal.reader');
      if (item) {
        const reader = JSON.parse(item);
        const readers = await discoverReaders();
        const found = readers.find((r) => r.id === reader.id);
        if (found) {
          await connectToReader(found);
        }
      }
      Logger.watchObject(client, 'backend', {
        createConnectionToken: {
          docsUrl: 'https://stripe.com/docs/terminal/sdk/js#connection-token',
        },
        registerDevice: {
          docsUrl:
            'https://stripe.com/docs/terminal/readers/connecting/verifone-p400#register-reader',
        },
        createPaymentIntent: {
          docsUrl: 'https://stripe.com/docs/terminal/payments#create',
        },
        capturePaymentIntent: {
          docsUrl: 'https://stripe.com/docs/terminal/payments#capture',
        },
        savePaymentMethodToCustomer: {
          docsUrl: 'https://stripe.com/docs/terminal/payments/saving-cards',
        },
      });
      Logger.watchObject(terminal, 'terminal', {
        discoverReaders: {
          docsUrl:
            'https://stripe.com/docs/terminal/js-api-reference#discover-readers',
        },
        connectReader: {
          docsUrl: 'https://stripe.com/docs/terminal/js-api-reference#connect-reader',
        },
        disconnectReader: {
          docsUrl: 'https://stripe.com/docs/terminal/js-api-reference#disconnect',
        },
        setReaderDisplay: {
          docsUrl:
            'https://stripe.com/docs/terminal/js-api-reference#set-reader-display',
        },
        collectPaymentMethod: {
          docsUrl:
            'https://stripe.com/docs/terminal/js-api-reference#collect-payment-method',
        },
        cancelCollectPaymentMethod: {
          docsUrl:
            'https://stripe.com/docs/terminal/js-api-reference#cancel-collect-payment-method',
        },
        processPayment: {
          docsUrl:
            'https://stripe.com/docs/terminal/js-api-reference#process-payment',
        },
        readReusableCard: {
          docsUrl:
            'https://stripe.com/docs/terminal/js-api-reference#read-reusable-card',
        },
        cancelReadReusableCard: {
          docsUrl:
            'https://stripe.com/docs/terminal/js-api-reference#cancel-read-reusable-card',
        },
        collectRefundPaymentMethod: {
          docsUrl: 'https://stripe.com/docs/terminal/js-api-reference#stripeterminal-collectrefundpaymentmethod',
        },
        processRefund: {
          docsUrl: 'https://stripe.com/docs/terminal/js-api-reference#stripeterminal-processrefund',
        },
        cancelCollectRefundPaymentMethod: {
          docsUrl: 'https://stripe.com/docs/terminal/js-api-reference#stripeterminal-cancelcollectrefundpaymentmethod',
        },
      });
    }
    init()
      .then(() => {})
      .catch(() => {});
  }, [connectToReader, discoverReaders, terminal]);

  function renderForm() {
    const {
      cancelablePayment,
      reader,
      discoveredReaders,
    } = state;
    if (reader === null) {
      return (
        <Readers
          onClickDiscover={() => discoverReaders()}
          onSubmitRegister={registerAndConnectNewReader}
          readers={discoveredReaders}
          onConnectToReader={connectToReader}
          listLocations={client.listLocations}
        />
      );
    }
    return (
      <CartForm
        workFlowDisabled={isWorkflowDisabled()}
        cancelablePayment={cancelablePayment}
        onClickCancelPayment={cancelPendingPayment}
        onClickCollectCardPayments={() => runWorkflow('collectPayment', collectCardPayment)}
        chargeAmount={state.chargeAmount}
        currency={state.currency}
        onChangeCurrency={(currency) => updateCurrency(currency)}
        onChangeChargeAmount={(amount) => updateChargeAmount(amount)}
      />
    );
  }

  const { reader } = state;
  return (
    <div>
      <GridContainer justify="center" alignItems="center" style={{ gap: 20 }}>
        <GridItem xs={12} sm={12}>
          <ConnectionInfo
            reader={reader}
            onClickDisconnect={disconnectReader}
          />
        </GridItem>
        <GridItem xs={12} sm={12}>
          {renderForm()}
        </GridItem>
        <GridItem xs={12} sm={12}>
          <ThermalPrinter />
        </GridItem>
        <GridItem xs={12} sm={12}>
          <Logs />
        </GridItem>
      </GridContainer>
    </div>
  );
}
