import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../components/Button/Button.jsx';
import Group from '../components/Group/Group.jsx';
import Section from '../components/Section/Section.js';
import Text from '../components/Text/Text.jsx';
import TextInput from '../components/TextInput/TextInput.jsx';

export default function RegisterNewReader({
  listLocations,
  onClickCancel,
  onSubmitRegister,
}) {
  const [readerCode, setReaderCode] = useState(null);
  const [readerLabel, setReaderLabel] = useState(null);
  const [readerLocationId, setReaderLocationId] = useState(null);

  useEffect(() => {
    listLocations().then((locations) => {
      setReaderLocationId(locations.length >= 1 ? locations[0].id : null);
    });
  }, [listLocations]);

  const onTriggerSubmitRegister = (event) => {
    event.preventDefault();
    onSubmitRegister(readerLabel, readerCode, readerLocationId);
  };

  return (
    <Section alignment={{ margin: 'auto' }}>
      <form onSubmit={onTriggerSubmitRegister}>
        <Group direction="column" spacing={16}>
          <Group direction="column" spacing={8}>
            <Text size={16} color="dark">
              Register new reader
            </Text>
            <Text size={12} color="lightGrey">
              Enter the key sequence 0-7-1-3-9 on the reader to display its
              unique registration code.
            </Text>
          </Group>
          <Group direction="column" spacing={8}>
            <Text size={14} color="darkGrey">
              Registration code
            </Text>
            <TextInput
              placeholder="quick-brown-fox"
              value={readerCode}
              onChange={setReaderCode}
              ariaLabel="Registration code"
            />
            <Text size={14} color="darkGrey">
              Reader label
            </Text>
            <TextInput
              placeholder="Front desk"
              value={readerLabel}
              onChange={setReaderLabel}
              ariaLabel="Reader label"
            />
          </Group>
          <Group direction="row" alignment={{ justifyContent: 'flex-end' }}>
            <Button color="white" onClick={onClickCancel}>
              <Text color="darkGrey" size={14}>
                Cancel
              </Text>
            </Button>
            <Button
              type="submit"
              disabled={
                readerCode === null
                || readerCode === ''
                || readerLabel === null
                || readerCode === ''
              }
              color="primary"
            >
              <Text color="white" size={14}>
                Register
              </Text>
            </Button>
          </Group>
        </Group>
      </form>
    </Section>
  );
}

RegisterNewReader.propTypes = {
  onClickCancel: PropTypes.func.isRequired,
  onSubmitRegister: PropTypes.func.isRequired,
  listLocations: PropTypes.func.isRequired,
};
