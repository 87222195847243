import React, { useState } from 'react';
import cx from 'classnames';
import {
  Switch, Route, Redirect,
} from 'react-router-dom';

// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Footer from 'components/Footer/Footer.js';
import Sidebar from 'components/Sidebar/Sidebar.js';

import routes from 'routes.js';
import Parse from 'services/Parse.js';
import styles from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.js';

const cashdropLogo = require('assets/img/cashdrop-logo.png');

let ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const user = Parse.User.current();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image] = React.useState(undefined);
  const [color] = React.useState('blue');
  const [bgColor] = React.useState('black');
  const [logo] = React.useState(cashdropLogo);
  const [isLoggedIn] = useState(!!user);
  const [ticketBusiness] = useState(user && user.get('ticketBusiness'));
  // styles
  const classes = useStyles();
  const mainPanelClasses = `${classes.mainPanel
  } ${
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1,
    })}`;
  // ref for main panel div
  const mainPanel = React.createRef();

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
      window.removeEventListener('resize', resizeFunction);
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => window.location.pathname !== '/admin/full-screen-maps';
  const getActiveRoute = (views) => {
    const activeRoute = 'Default Brand Text';
    for (let i = 0; i < views.length; i += 1) {
      if (views[i].collapse) {
        const collapseActiveRoute = getActiveRoute(views[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (
        window.location.href.indexOf(views[i].layout + views[i].path) !== -1
      ) {
        return views[i].name;
      }
    }
    return activeRoute;
  };
  const getRoutes = (views) => views.map((prop) => {
    if (prop.collapse) {
      return getRoutes(prop.views);
    }
    if (prop.layout === '/admin') {
      const Component = prop.component;
      const compProps = { ticketBusiness };
      return (
        <Route
          path={prop.layout + prop.path}
          component={() => <Component {...compProps} />}
          key={`admin_${prop.path.substring(1)}`}
        />
      );
    }
    return null;
  });
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };

  if (!isLoggedIn) {
    return <Redirect to="/auth/login" />;
  }

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        ticketBusiness={ticketBusiness}
        logoText="CashDrop"
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          ticketBusiness={ticketBusiness}
          {...rest}
        />
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/admin" to="/admin/dashboard" />
              </Switch>
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/admin" to="/admin/dashboard" />
            </Switch>
          </div>
        )}
        {getRoute() ? <Footer fluid /> : null}
      </div>
    </div>
  );
}
