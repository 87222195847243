import React from 'react';
import PropTypes from 'prop-types';

import Button from '../components/Button/Button.jsx';
import Icon from '../components/Icon/Icon.jsx';
import Group from '../components/Group/Group.jsx';
import Section from '../components/Section/Section.js';
import Text from '../components/Text/Text.jsx';
import TextInput from '../components/TextInput/TextInput.jsx';
import Select from '../components/Select/Select.jsx';

const CURRENCIES = [
  { value: 'usd', label: 'USD' },
];

export default function CartForm(props) {
  const {
    workFlowDisabled,
    cancelablePayment,
    onClickCancelPayment,
    onClickCollectCardPayments,
    chargeAmount,
    onChangeCurrency,
    onChangeChargeAmount,
  } = props;
  return (
    <>
      <Group direction="column" spacing={0}>
        <Section position="first" alignment={{ margin: 'auto' }}>
          <Text size={16} color="dark">
            Checkout
          </Text>
        </Section>
        <Section position="last" alignment={{ margin: 'auto' }}>
          <Group direction="column">
            <Group
              direction="row"
              alignment={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text size={12} color="dark">
                Charge amount
              </Text>
              <TextInput
                value={chargeAmount}
                onChange={onChangeChargeAmount}
                ariaLabel="Charge amount"
              />
            </Group>
            <Group
              direction="row"
              alignment={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text size={12} color="dark">
                Currency
              </Text>
              <Select
                items={CURRENCIES}
                value={CURRENCIES[0]}
                onChange={onChangeCurrency}
                ariaLabel="Currency"
              />
            </Group>
            <Button
              color="white"
              onClick={onClickCollectCardPayments}
              disabled={workFlowDisabled}
              justifyContent="left"
            >
              <Group direction="row">
                <Icon icon="payments" />
                <Text color="blue" size={14}>
                  Collect card payment
                </Text>
              </Group>
            </Button>
            <Button
              color="white"
              onClick={onClickCancelPayment}
              disabled={!cancelablePayment}
              justifyContent="left"
            >
              <Group direction="row">
                <Icon icon="cancel" />
                <Text color="blue" size={14}>
                  Cancel payment
                </Text>
              </Group>
            </Button>
          </Group>
        </Section>
      </Group>
    </>
  );
}
CartForm.defaultProps = {
  workFlowDisabled: null,
};
CartForm.propTypes = {
  cancelablePayment: PropTypes.bool.isRequired,
  chargeAmount: PropTypes.number.isRequired,
  onChangeCurrency: PropTypes.func.isRequired,
  onChangeChargeAmount: PropTypes.func.isRequired,
  onClickCollectCardPayments: PropTypes.func.isRequired,
  onClickCancelPayment: PropTypes.func.isRequired,
  workFlowDisabled: PropTypes.string,
};
