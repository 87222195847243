import React, { useEffect, useState } from 'react';
import { css } from 'emotion';
import './Logs.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Button from '../components/Button/Button.jsx';
import Group from '../components/Group/Group.jsx';
import Logger from '../logger';
import Text from '../components/Text/Text.jsx';
import Link from '../components/Link/Link.jsx';

export default function Logs() {
  const [logs, setLogs] = useState([]);

  function collect(log) {
    setLogs((prev) => [log, ...prev]);
  }

  useEffect(() => {
    Logger.setCollectors([{ collect }]);
  }, []);

  const clearLogs = () => {
    setLogs([]);
  };

  function renderEmpty() {
    return (
      <div className="Logs-empty">
        <Group
          direction="column"
          spacing={8}
          alignment={{ alignItems: 'center', textAlign: 'center' }}
        >
          <Text size={14}>No Logs Found</Text>
        </Group>
      </div>
    );
  }

  function renderJSON(resp) {
    if (resp) {
      try {
        return JSON.stringify(JSON.parse(resp), undefined, 2);
      } catch (e) {
        return resp;
      }
    } else return null;
  }

  function renderRequestJSON(req) {
    const json = JSON.parse(req);
    if (json.length === 0) {
      return '()';
    }
    return JSON.stringify(json[0], undefined, 2);
  }

  function renderLogs() {
    return (
      <div className="Logs-content">
        <TransitionGroup className="todo-list">
          {logs.map((log) => {
            const exception = log.exception ? 'EXCEPTION' : 'VOID';
            const returnType = log.response
              ? 'RESPONSE'
              : exception;
            return (
              <CSSTransition key={log.id} timeout={500} classNames="fade">
                <div
                  className={css`
                    border-bottom: 1px solid #4e566d;
                    padding: 20px;
                  `}
                >
                  <Group direction="column">
                    <Group
                      direction="row"
                      alignment={{ justifyContent: 'space-between' }}
                    >
                      <Group
                        direction="row"
                        alignment={{ alignItems: 'center' }}
                      >
                        <Link
                          size={14}
                          href={log.docsUrl}
                          text={log.method}
                          newWindow
                        />
                      </Group>
                      <Text color="lightGrey" size={12}>
                        {new Date(log.start_time_ms).toLocaleString()}
                      </Text>
                    </Group>
                    <Text color="lightGrey">REQUEST</Text>
                    <Text color="lightGrey">
                      <pre>
                        <code
                          className={css`
                            color: #8792a2;
                          `}
                        >
                          {renderRequestJSON(log.request)}
                        </code>
                      </pre>
                    </Text>

                    <Text color="lightGrey">{returnType}</Text>
                    {returnType !== 'VOID' ? (
                      <Text color="lightGrey">
                        <pre>
                          <code
                            className={css`
                              color: #8792a2;
                            `}
                          >
                            {renderJSON(log.response || log.exception)}
                          </code>
                        </pre>
                      </Text>
                    ) : (
                      ''
                    )}
                  </Group>
                </div>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </div>
    );
  }

  return (
    <div className="Logs">
      <div className="Logs-header">
        <Text size="16px" color="grey">
          Logs
        </Text>
        <Button color="textDark" onClick={clearLogs}>
          Clear
        </Button>
      </div>
      <div className="Logs-body">
        {logs.length < 1 ? renderEmpty() : renderLogs(logs)}
      </div>
    </div>
  );
}
