class Logger {
  static collectors = [];

  static serializer = Promise.resolve;

  static setCollectors(collectors) {
    this.collectors = collectors;
  }

  static async forwardToCollectors(log) {
    this.collectors.forEach((collector) => collector.collect(log));
  }

  static log(log) {
    Logger.forwardToCollectors(log);
  }

  static tracedFn(methodName, docsUrl, fn) {
    return async function (...args) {
      const method = methodName || fn.name;
      const requestString = JSON.stringify(args);
      const UUID = Math.floor(Math.random() * 10000000).toString();
      const trace = {
        id: UUID,
        start_time_ms: new Date().valueOf(),
        method,
        docsUrl,
        request: requestString,
        response: null,
        exception: null,
      };

      try {
        const response = await fn.apply(this, args);
        trace.response = JSON.stringify(response);
        return response;
      } catch (e) {
        trace.exception = e.message;
        throw e;
      } finally {
        Logger.log(trace);
      }
    };
  }

  static watchObject(obj, objName, methodsMetadata) {
    const objProtoype = Object.getPrototypeOf(obj);
    Object.getOwnPropertyNames(objProtoype)
      .filter((property) => methodsMetadata[property] !== undefined)
      .forEach((instanceMethodName) => {
        obj[instanceMethodName] = Logger.tracedFn(
          `${objName}.${instanceMethodName}`,
          methodsMetadata[instanceMethodName].docsUrl,
          objProtoype[instanceMethodName],
        );
      });
  }
}

export default Logger;
