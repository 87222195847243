import React from 'react';
import PropTypes from 'prop-types';

import Button from '../components/Button/Button.jsx';
import Group from '../components/Group/Group.jsx';
import Icon from '../components/Icon/Icon.jsx';
import Section from '../components/Section/Section.js';
import Text from '../components/Text/Text.jsx';

export default function ConnectionInfo({ reader, onClickDisconnect }) {
  return (
    <Group direction="column" spacing={0}>
      <Section alignment={{ margin: 'auto' }}>
        {reader ? (
          <Group
            direction="row"
            alignment={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Group direction="row">
              <span>
                <Icon icon="keypad" />
              </span>
              <Text truncate color="dark" size={14}>
                {reader.label}
              </Text>
            </Group>
            <Button color="text" onClick={onClickDisconnect}>
              Disconnect
            </Button>
          </Group>
        ) : (
          <Group direction="row">
            <span>
              <Icon icon="keypad" />
            </span>
            <Text color="lightGrey" size={14}>
              No reader connected
            </Text>
          </Group>
        )}
      </Section>
    </Group>
  );
}

ConnectionInfo.defaultProps = {
  reader: null,
};

ConnectionInfo.propTypes = {
  reader: PropTypes.objectOf(PropTypes.any),
  onClickDisconnect: PropTypes.func.isRequired,
};
