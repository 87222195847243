import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../components/Button/Button.jsx';
import Group from '../components/Group/Group.jsx';
import ReaderIcon from '../components/Icon/reader/ReaderIcon.jsx';
import Section from '../components/Section/Section.js';
import Text from '../components/Text/Text.jsx';

export default function DiscoverReaders({
  readers,
  onConnectToReader,
  onClickDiscover,
  onClickRegister,
}) {
  const [discoveryInProgress, setDiscoveryInProgress] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const onTriggerDiscoverReaders = async () => {
    setDiscoveryInProgress(true);
    setRequestInProgress(true);

    try {
      await onClickDiscover();
    } finally {
      setDiscoveryInProgress(false);
      setRequestInProgress(false);
    }
  };

  const onTriggerConnectToReader = (reader) => async () => {
    setRequestInProgress(true);
    try {
      await onConnectToReader(reader);
    } finally {
      setRequestInProgress(false);
    }
  };

  function renderReaders() {
    if (discoveryInProgress) {
      return (
        <Section position="middle" alignment={{ margin: 'auto' }}>
          <Text size={14} color="darkGrey">
            Discovering...
          </Text>
        </Section>
      );
    } if (readers.length >= 1) {
      return readers.map((reader) => {
        const isOffline = reader.status === 'offline';
        return (
          <Section position="middle" key={reader.id} alignment={{ margin: 'auto' }}>
            <Group
              direction="row"
              alignment={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Group direction="row">
                <ReaderIcon />
                <Group direction="column">
                  {reader.label}
                  <Group direction="row">
                    <Text size={11} color="darkGrey">
                      {reader.serial_number}
                    </Text>
                  </Group>
                </Group>
              </Group>
              <Button
                disabled={isOffline || requestInProgress}
                color={isOffline || requestInProgress ? 'white' : 'primary'}
                onClick={onTriggerConnectToReader(reader)}
              >
                <Text size={14} color={isOffline || requestInProgress ? 'darkGrey' : 'white'}>
                  {isOffline ? 'Offline' : 'Connect'}
                </Text>
              </Button>
            </Group>
          </Section>
        );
      });
    }
    return (
      <Section position="middle" alignment={{ margin: 'auto' }}>
        <Group
          direction="column"
          spacing={12}
          alignment={{ justifyContent: 'center', alignItems: 'center' }}
        >
          <ReaderIcon />

          <Text color="darkGrey" size={12}>
            Register a new reader, then discover readers on your account. You can also purchase a reader
            <a target="_blank" rel="noopener noreferrer" href="https://dashboard.stripe.com/terminal/shop"> here</a>
          </Text>
        </Group>
      </Section>
    );
  }

  return (
    <Group direction="column" spacing={0}>
      <Section position="first" alignment={{ margin: 'auto' }}>
        <Group
          direction="row"
          alignment={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text size={16} color="dark">
            Connect to a reader
          </Text>
        </Group>
      </Section>

      {renderReaders()}
      <Section position="last" alignment={{ margin: 'auto' }}>
        <Group
          direction="row"
          alignment={{ justifyContent: 'center' }}
          spacing={8}
        >
          <Button onClick={onClickRegister} disabled={requestInProgress}>
            <Text size={14} color="dark">
              Register reader
            </Text>
          </Button>
          <Button onClick={onTriggerDiscoverReaders} disabled={requestInProgress}>
            <Text size={14} color="dark">
              Discover
            </Text>
          </Button>
        </Group>
      </Section>
    </Group>
  );
}

DiscoverReaders.propTypes = {
  readers: PropTypes.arrayOf(PropTypes.any).isRequired,
  onClickDiscover: PropTypes.func.isRequired,
  onClickRegister: PropTypes.func.isRequired,
  onConnectToReader: PropTypes.func.isRequired,
};
