/* eslint-disable */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Hidden from '@material-ui/core/Hidden';
import Popper from '@material-ui/core/Popper';
import Divider from '@material-ui/core/Divider';

// @material-ui/icons
import Person from '@material-ui/icons/Person';
import Notifications from '@material-ui/icons/Notifications';
import Dashboard from '@material-ui/icons/Dashboard';
import Search from '@material-ui/icons/Search';

// core components
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';

import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js';

import Parse from 'services/Parse.js';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const [openNotification, setOpenNotification] = React.useState(null);
  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
    // window.location.href = '/admin/pending';
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const [openProfile, setOpenProfile] = React.useState(null);
  const [hasLoggedOut, setHasLoggedOut] = useState(false);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [pendingSubscription, setPendingSubscription] = useState(null);

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const logOut = () => {
    setHasLoggedOut(true);
    Parse.User.logOut().then(() => {}).catch(() => {});
  };
  const classes = useStyles();
  const { rtlActive } = props;
  const searchButton = `${classes.top
  } ${
    classes.searchButton
  } ${
    classNames({
      [classes.searchRTL]: rtlActive,
    })}`;
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });
  if (hasLoggedOut) {
    return <Redirect to="/auth/login" />;
  }
  useEffect(() => {
    const getPendingOrders = async () => {
      const orders = await Parse.Cloud.run('GetPendingOrders', { ticketBusinessId: props.ticketBusiness.id });
      setPendingOrders(orders);
    };
    getPendingOrders();
    const query = new Parse.Query('Purchase');
    query.equalTo('ticketBusiness', props.ticketBusiness);
    query.equalTo('status', 'pending');
    query.subscribe().then((subscription) => {
      setPendingSubscription(subscription);
      subscription.on('create', (object) => {
        getPendingOrders().then(() => {}).catch(() => {});
      });
      subscription.on('update', (object) => {
        getPendingOrders().then(() => {}).catch(() => {});
      });
    });
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (pendingSubscription) {
        pendingSubscription.unsubscribe();
      }
    };
  }, []);

  return (
    <div className={wrapper}>
      {/* <CustomInput
        rtlActive={rtlActive}
        formControlProps={{
          className: `${classes.top} ${classes.search}`,
        }}
        inputProps={{
          placeholder: rtlActive ? 'بحث' : 'Search',
          inputProps: {
            'aria-label': rtlActive ? 'بحث' : 'Search',
            className: classes.searchInput,
          },
        }}
      />
      <Button
        color="white"
        aria-label="edit"
        justIcon
        round
        className={searchButton}
      >
        <Search className={`${classes.headerLinksSvg} ${classes.searchIcon}`} />
      </Button> */}
      {/* <Button
        color="transparent"
        simple
        aria-label="Dashboard"
        justIcon
        className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
        muiClasses={{
          label: rtlActive ? classes.labelRTL : '',
        }}
      >
        <Dashboard
          className={
            `${classes.headerLinksSvg
            } ${
              rtlActive ? `${classes.links} ${classes.linksRTL}` : classes.links}`
          }
        />
        <Hidden mdUp implementation="css">
          <span className={classes.linkText}>
            {rtlActive ? 'لوحة القيادة' : 'Dashboard'}
          </span>
        </Hidden>
      </Button> */}
      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={openNotification ? 'notification-menu-list' : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : '',
          }}
        >
          <Notifications
            className={
              `${classes.headerLinksSvg
              } ${
                rtlActive
                  ? `${classes.links} ${classes.linksRTL}`
                  : classes.links}`
            }
          />
          <span className={classes.notifications}>{pendingOrders.length }</span>
          <Hidden mdUp implementation="css">
            <span
              onClick={handleClickNotification}
              className={classes.linkText}
            >
              {rtlActive ? 'إعلام' : 'Pending Orders'}
            </span>
          </Hidden>
        </Button>
      </div>

      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          aria-owns={openProfile ? 'profile-menu-list' : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : '',
          }}
        >
          <Person
            className={
              `${classes.headerLinksSvg
              } ${
                rtlActive
                  ? `${classes.links} ${classes.linksRTL}`
                  : classes.links}`
            }
          />
          <Hidden mdUp implementation="css">
            <span onClick={handleClickProfile} className={classes.linkText}>
              {rtlActive ? 'الملف الشخصي' : 'Profile'}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: '0 0 0' }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    {/* <MenuItem
                      onClick={handleCloseProfile}
                      className={dropdownItem}
                    >
                      {rtlActive ? 'الملف الشخصي' : 'Profile'}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={dropdownItem}
                    >
                      {rtlActive ? 'الإعدادات' : 'Settings'}
                    </MenuItem>
                    <Divider light /> */}
                    <MenuItem
                      onClick={logOut}
                      className={dropdownItem}
                    >
                      {rtlActive ? 'الخروج' : 'Log out'}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};
