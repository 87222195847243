// Client for the example terminal backend: https://github.com/stripe/example-terminal-backend
const Parse = require('parse/node');

class Client {
  constructor(url, stripeAccount) {
    this.url = url;
    this.stripeAccount = stripeAccount;
    this.listLocations = this.listLocations.bind(this);

    const applicationId = 'MK5KVBqIzhhM5tIwX9hrKnQLLKpHeJ9O0VHS4Fqp';
    const javascriptKey = 'CLStTSaozrHYjthLXzZkqoUCsJcgnFVQtb5rjtyu';
    let serverURL = 'https://web-cashdrop.herokuapp.com/parse';
    let liveQueryURL = 'wss://sugr-live-query.herokuapp.com';
    // console.log(process.env.NODE_ENV);
    if (process.env.NODE_ENV === 'development') {
      serverURL = 'http://localhost:1337/parse';
      liveQueryURL = 'ws://localhost:1337';
      if (process.env.NGROK_ENABLED === 'true') {
        serverURL = 'https://cashdrop.ngrok.io/parse';
        liveQueryURL = 'wss://cashdrop.ngrok.io';
      }
    }
    // console.log(serverURL);
    // const serverURL = 'http://rubens-macbook-pro.local:1337/parse';
    // const serverURL = 'http://Diamonds-MacBook-Pro.local:1337/parse';
    // const serverURL = 'https://horrible-cougar-30.serverless.social/parse';
    Parse.initialize(applicationId, javascriptKey);
    Parse.serverURL = serverURL;
    Parse.idempotency = true;
    Parse.CoreManager.set('REQUEST_ATTEMPT_LIMIT', 1);

    Parse.liveQueryServerURL = liveQueryURL;
    this.Parse = Parse;
  }

  createConnectionToken() {
    return this.Parse.Cloud.run('fetchConnectionToken', { stripeAccount: this.stripeAccount });
  }

  registerDevice({ label, registrationCode, location }) {
    return this.Parse.Cloud.run('registerReader', {
      stripeAccount: this.stripeAccount,
      label,
      registrationCode,
      location,
      expand: true,
    });
  }

  createPaymentIntent({
    amount, currency, description, paymentMethodTypes,
  }) {
    return this.Parse.Cloud.run('createPaymentIntent', {
      stripeAccount: this.stripeAccount,
      amount,
      currency,
      description,
      paymentMethodTypes,
      applicationFee: 0,
    });
  }

  capturePaymentIntent({ paymentIntentId }) {
    return this.Parse.Cloud.run('capturePaymentIntent', {
      stripeAccount: this.stripeAccount,
      intentId: paymentIntentId,
    });
  }

  savePaymentMethodToCustomer({ paymentMethodId }) {
    return this.Parse.Cloud.run('AttachPaymentMethodToCustomer', {
      stripeAccount: this.stripeAccount,
      paymentMethodId,
    });
  }

  listLocations() {
    return this.Parse.Cloud.run('listStripeLocations', { stripeAccount: this.stripeAccount });
  }
}

export default Client;
