import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DiscoverReaders from './DiscoverReaders.js';
import RegisterNewReader from './RegisterNewReader.js';

export default function Readers(props) {
  const {
    readers,
    onClickDiscover,
    onSubmitRegister,
    onConnectToReader,
    listLocations,
  } = props;
  const [mode, setMode] = useState('list');

  if (mode === 'list') {
    return (
      <DiscoverReaders
        onClickDiscover={onClickDiscover}
        onClickRegister={() => setMode('register')}
        onConnectToReader={onConnectToReader}
        readers={readers}
      />
    );
  }
  if (mode === 'register') {
    return (
      <RegisterNewReader
        onClickCancel={() => setMode('list')}
        onSubmitRegister={onSubmitRegister}
        listLocations={listLocations}
      />
    );
  }
  return null;
}

Readers.propTypes = {
  readers: PropTypes.arrayOf(PropTypes.any).isRequired,
  onClickDiscover: PropTypes.func.isRequired,
  onSubmitRegister: PropTypes.func.isRequired,
  onConnectToReader: PropTypes.func.isRequired,
  listLocations: PropTypes.func.isRequired,
};
